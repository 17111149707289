import React from 'react';
import {Container, Row} from 'react-bootstrap';
const InnerHeader = ({ backgroundImage, title}) => {
  const componentStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#0A6CC7',
    backgroundBlendMode: 'multiply',
    backgroundSize: 'cover',
  };
 
  return (
    <header data-aos="fade-up" className="innerHeader" style={componentStyle}>
        <Container fluid>
          <Row>
            <Container className="text-center">
              <h1 data-aos="zoom-in" data-aos-delay='100'>{title}</h1>
            </Container>
          </Row>
        </Container>
    </header>
  );
};

export default InnerHeader;
